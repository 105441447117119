/* global VXConfig */
import {VXPay} from "../../utils/VXPay";
import Flux    from '../../flux/Flux';
import '../generatedViews.js';
import "../../../../src/VX/Asset/Less/VX/CriticalDesktop.less";
import "../../../../src/VX/Asset/Less/VX/index.less";
import "../../../../src/VX/Asset/Less/VX/View/ContestsDetails.less";

function run() {
	window.hasRunEntryFunc = true;

	const {redirectUrl} = window.indexData;

	// load page incentives
	Flux.Conversion.loadPageLoadIncentives(VXConfig.routeName, VXConfig.routeArgs);

	const elements = document.querySelectorAll('.js-sexiest-selfies-signup-or-login');

	for (let i = 0; i < elements.length; i++) {
		elements[i].addEventListener('click', function(e) {
			e.preventDefault();
			e.stopPropagation();

			let targetUrl = redirectUrl;

			if (elements[i].dataset.redirectUrl) {
				targetUrl = elements[i].dataset.redirectUrl;
			}

			VXPay.onSuccess(function(result, success) {
				result.redirectTo = targetUrl;
				success(result);
			});
			VXPay.openSignup();
		}, true);
	}

}

if (!window.hasRunEntryFunc) {
	if (document.readyState !== 'loading') {
		run();
	} else {
		window.addEventListener('DOMContentLoaded', run);
	}
}
